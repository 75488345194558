// Styles
import "../styles/pages/mySpace.scss";

// Components
import LoadingPage from "../components/containers/LoadingPage.jsx";
import Header from "../components/organisms/header/Header.jsx";
import Footer from "../components/organisms/footer/Footer.jsx";
import Menu from "../components/organisms/menu/Menu.jsx";
import MiEspacioSectionCard from "../components/containers/MiEspacioSectionCard.jsx";

// Vars
import sigan from "../../assets/img/sigan.png";
import correo from "../../assets/img/correo.png";
import moodle from "../../assets/img/moodle.png";

const MySpace = () => {
    return (
        <>
            <LoadingPage />
            <Header />
            <Menu />
            <main className="mi_espacio_main">
                <div className="mi_espacio_separador"></div>
                <h1 className="mi_espacio_title">Mi Espacio</h1>
                <p className="mi_espacio_text">
                    Si eres estudiante, este lugar es exclusivo para ti.
                </p>
                <section className="mi_espacio_section_cards">
                    <MiEspacioSectionCard
                        img={correo}
                        title="Outlook"
                        icon="mail-unread"
                        text="Accede a tu correo institucional para mantenerte en contacto con docentes, recibir notificaciones importantes y gestionar tus comunicaciones académicas."
                        link="http://mail.office365.com/"
                    />
                    <MiEspacioSectionCard
                        img={moodle}
                        title="Moodle"
                        icon="book"
                        text="Ingresa a la plataforma de aprendizaje virtual para acceder a tus cursos, materiales educativos, realizar tareas y participar en foros académicos."
                        link="https://virtual.upn.edu.co/login/index.php"
                    />
                    <MiEspacioSectionCard
                        img={sigan}
                        title="Sigan"
                        icon="golf"
                        text="Consulta tu información académica, como notas, horarios y estado de inscripción, y realiza trámites administrativos desde el sistema académico Sigan."
                        link="http://sigan.pedagogica.edu.co/sigan/"
                    />
                </section>
            </main>
            <Footer />
        </>
    );
};

export default MySpace;
