import { useRef } from "react";

import "./header.scss";

// Components
import LogoUpn from "../../molecules/header/LogoUpn.jsx";
import BtnLogin from "../../molecules/header/BtnLogin.jsx";
import Gov from "../../molecules/header/Gov.jsx";
import Images from "../../molecules/header/Images.jsx";

// Hooks
import useScroll from "./hooks/useScroll.jsx";

// Context
import { useGeneralContext } from "../../../../models/context/GeneralContext.jsx";

// Vars
import logoSmall from "../../../../assets/iconos/logo-small.svg";
import logoLarge from "../../../../assets/iconos/logo-large.svg";

const Header = () => {
    const { webServices } = useGeneralContext();
    const headerRef = useRef(null);
    const scrolled = useScroll(headerRef);

    return (
        <>
            <Gov url={webServices.urlHeader} />

            <header ref={headerRef} className={`header ${scrolled ? "scrolled" : ""}`}>
                <section>
                    <LogoUpn logoSmall={logoSmall} logoLarge={logoLarge} />
                    <BtnLogin text="Aula Virtual" icon="person-circle-outline" url="https://virtual.upn.edu.co/login/index.php" />
                </section>

                <Images url={webServices.urlImages} />
            </header>
        </>
    );
};

export default Header;
